import React from "react";
import classNames from "classnames";

import ButtonLoader from "components/button-loader";
import button from "./button";
import styles from "./button.module.scss";

const Button: React.FC<button.props> = ({
  blocked = false,
  type = "button",
  children,
  color = "blue",
  onClick,
  pending
}) => {
  return (
    <button
      type={type}
      className={classNames(
        styles.button,
        styles[color],
        pending && styles.pending,
        blocked && styles.blocked
      )}
      onClick={(event) => {
        if (pending || blocked) return;
        onClick?.(event);
      }}
    >
      <span className={styles.text}>
        <span className={classNames(pending && styles.hidden)}>{children}</span>
        {pending && (
          <span className={styles.loading}>
            <ButtonLoader />
          </span>
        )}
      </span>
    </button>
  );
};

export default Button;
