import { AxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import global from "global";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import {
  education_levels,
  locations,
  family_statuses,
  employment_spheres,
  branches,
  type_of_products
} from "utils/constants";
import prettierNumber from "utils/prettier-number";
import styles from "./scoring.module.scss";

const initialValidationState = {
  education_level: "",
  location: "",
  family_status: "",
  employment_sphere: "",
  branch: "",
  type_of_product: "",
  bank_interest_rate: "",
  age: "",
  gender: "",
  presence_of_housing: "",
  monthly_income: "",
  quantity_of_dependents: ""
};

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);

  const dispatch = useDispatch();
  const [macroData, setMicroData] = React.useState<global.macroData>();
  const [pending, setPending] = React.useState(false);
  const [pendingConstantValues, setPendingConstantValues] = useState(false);
  const [validationError, setValidationError] = React.useState(initialValidationState);

  const [form, setForm] = React.useState({
    education_level: {
      key: "",
      value: ""
    },
    location: {
      key: "",
      value: ""
    },
    family_status: {
      key: "",
      value: ""
    },
    employment_sphere: {
      key: "",
      value: ""
    },
    branch: {
      key: "",
      value: ""
    },
    type_of_product: {
      key: "",
      value: ""
    },
    age: "",
    gender: {
      key: "",
      value: ""
    },
    presence_of_housing: {
      key: "",
      value: ""
    },
    monthly_income: "",
    quantity_of_dependents: ""
  });

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;

    let newValidationError = { ...initialValidationState };

    if (form.education_level.key === "") newValidationError.age = "Education level is required";
    if (form.location.key === "") newValidationError.location = "Client residence is required";
    if (form.family_status.key === "")
      newValidationError.family_status = "Family status is required";
    if (form.employment_sphere.key === "")
      newValidationError.employment_sphere = "Employment sphere is required";
    if (form.branch.key === "") newValidationError.branch = "Branch level is required";
    if (form.type_of_product.key === "")
      newValidationError.type_of_product = "Type of product is required";
    if (form.age === "") newValidationError.age = "Age is required";
    if (form.gender.key === "") newValidationError.gender = "Gender is required";
    if (form.presence_of_housing.key === "")
      newValidationError.presence_of_housing = "Presence of housing is required";
    if (form.monthly_income === "")
      newValidationError.monthly_income = "Monthly income is required";
    if (form.quantity_of_dependents === "")
      newValidationError.quantity_of_dependents = "Quantity of dependents is required";

    setValidationError(newValidationError);

    if (JSON.stringify(newValidationError) !== JSON.stringify(initialValidationState)) return;

    setPending(true);

    request
      .post(`/applications/${id}/scoring`, {
        education_level: form.education_level.key,
        location: form.location.key,
        family_status: form.family_status.key,
        employment_sphere: form.employment_sphere.key,
        branch: form.branch.key,
        type_of_product: form.type_of_product.key,
        age: form.age,
        inflation_rate: macroData?.inflation_rate,
        monthly_income: form.monthly_income,
        quantity_of_dependents: form.quantity_of_dependents,
        gender: form.gender.key,
        presence_of_housing: form.presence_of_housing.key === "Yes"
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(() => dispatch.application.FETCH_APPLICATION_BY_ID(id), 400);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 409) toast.error((e.response.data as any).detail);
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    setPendingConstantValues(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMicroData(d))
      .then(() => setPendingConstantValues(false));
  }, []);

  return (
    <div className={styles.home}>
      {!pendingConstantValues ? (
        <>
          <div className={styles.fields}>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Credit data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Amount</div>
                  <div className={styles.value}>
                    {prettierNumber(contextApplication.data?.loan_amount ?? 0)} GTQ
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Loan interest rate</div>
                  <div className={styles.value}>
                    {contextApplication.data?.bank_interest_rate} %
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Duration</div>
                  <div className={styles.value}>
                    {contextApplication.data?.duration_at_disbursement_months} month(s)
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Threshold</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.threshold
                      ? (contextApplication.data?.scoring_log?.threshold * 100).toFixed()
                      : Math.round((macroData?.threshold ?? 0) * 100)}{" "}
                    %
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Macro variables</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Inflation rate</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.inflation_rate ??
                      macroData?.inflation_rate}{" "}
                    %
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Interest rate</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.interest_rate ??
                      macroData?.interest_rate}{" "}
                    %
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Remittances</div>
                  <div className={styles.value}>
                    {contextApplication.data?.scoring_log?.remittances ?? macroData?.remittances}{" "}
                  </div>
                </div>
              </div>
            </div>
            {!!contextApplication.data?.scoring_log && (
              <>
                <div className={styles.creditDataBox}>
                  <div className={styles.title}>Customer data</div>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <div className={styles.key}>Education level</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.education_level}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Client residence</div>
                      <div className={styles.value}>
                        {
                          locations.find(
                            (e) => e.text === contextApplication.data?.scoring_log.location
                          )?.code
                        }{" "}
                        <span className={styles.value__desc}>
                          {contextApplication.data.scoring_log.location}
                        </span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Family status</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.family_status}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Employment sphere</div>
                      <div className={styles.value}>
                        {
                          employment_spheres.find(
                            (e) => e.text === contextApplication.data?.scoring_log.employment_sphere
                          )?.code
                        }{" "}
                        <span className={styles.value__desc}>
                          {contextApplication.data.scoring_log.employment_sphere}
                        </span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Branch</div>
                      <div className={styles.value}>
                        {
                          branches.find(
                            (e) => e.text === contextApplication.data?.scoring_log.branch
                          )?.code
                        }{" "}
                        <span className={styles.value__desc}>
                          {contextApplication.data.scoring_log.branch}
                        </span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Type of product</div>
                      <div className={styles.value}>
                        {
                          type_of_products.find(
                            (e) => e.text === contextApplication.data?.scoring_log.type_of_product
                          )?.code
                        }{" "}
                        <span className={styles.value__desc}>
                          {contextApplication.data.scoring_log.type_of_product}
                        </span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Age</div>
                      <div className={styles.value}>{contextApplication.data.scoring_log.age}</div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Gender</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.gender}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Presence of housing</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.presence_of_housing ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Monthly income</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.monthly_income}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.key}>Quantity of dependents</div>
                      <div className={styles.value}>
                        {contextApplication.data.scoring_log.quantity_of_dependents}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!contextApplication.data?.scoring_log && (
              <>
                <div className={styles.fieldGroup}>
                  <div className={styles.fieldTitle}>Customer data</div>
                  <div className={styles.fieldList}>
                    <Input
                      title="Education level"
                      id="education_level"
                      value={form.education_level.key}
                      type="select"
                      options={education_levels.map((education_level) => ({
                        key: education_level,
                        value: education_level
                      }))}
                      error={validationError.education_level}
                      onSelect={(option) => setForm({ ...form, education_level: option })}
                    />
                    <Input
                      title="Client residence"
                      id="location"
                      value={form.location.key}
                      type="select"
                      showPlaceholder
                      hasFilterText
                      options={locations.map((location) => ({
                        key: location.text,
                        value: location.code
                      }))}
                      error={validationError.location}
                      onSelect={(option) => setForm({ ...form, location: option })}
                    />
                    <Input
                      title="Family status"
                      id="family_status"
                      value={form.family_status.key}
                      type="select"
                      options={family_statuses.map((family_status) => ({
                        key: family_status,
                        value: family_status
                      }))}
                      error={validationError.family_status}
                      onSelect={(option) => setForm({ ...form, family_status: option })}
                    />
                    <Input
                      title="Employment sphere"
                      id="employment_sphere"
                      hasFilterText
                      showPlaceholder
                      value={form.employment_sphere.key}
                      type="select"
                      options={employment_spheres.map((employment_sphere) => ({
                        key: employment_sphere.text,
                        value: employment_sphere.code
                      }))}
                      error={validationError.employment_sphere}
                      onSelect={(option) => setForm({ ...form, employment_sphere: option })}
                    />
                    <Input
                      title="Branch"
                      id="branch"
                      hasFilterText
                      showPlaceholder
                      value={form.branch.key}
                      type="select"
                      options={branches.map((branch) => ({
                        key: branch.text,
                        value: branch.code
                      }))}
                      error={validationError.branch}
                      onSelect={(option) => setForm({ ...form, branch: option })}
                    />
                    <Input
                      title="Type of product"
                      id="type_of_product"
                      hasFilterText
                      value={form.type_of_product.key}
                      type="select"
                      showPlaceholder
                      options={type_of_products.map((type_of_product) => ({
                        key: type_of_product.text,
                        value: type_of_product.code
                      }))}
                      error={validationError.type_of_product}
                      onSelect={(option) => setForm({ ...form, type_of_product: option })}
                    />
                    <Input
                      title="Age"
                      value={form.age}
                      type="integer"
                      onInput={(value) => setForm({ ...form, age: value })}
                      step={1}
                      min={18}
                      max={74}
                      error={validationError.age}
                    />
                    <Input
                      title="Gender"
                      id="gender"
                      value={form.gender.value}
                      type="select"
                      options={[
                        {
                          key: "Male",
                          value: "Male"
                        },
                        {
                          key: "Female",
                          value: "Female"
                        }
                      ]}
                      error={validationError.gender}
                      onSelect={(option) => setForm({ ...form, gender: option })}
                    />
                    <Input
                      title="Presence of housing"
                      id="presence_of_housing"
                      value={form.presence_of_housing.value}
                      type="select"
                      options={[
                        {
                          key: "Yes",
                          value: "Yes"
                        },
                        {
                          key: "No",
                          value: "No"
                        }
                      ]}
                      error={validationError.presence_of_housing}
                      onSelect={(option) => setForm({ ...form, presence_of_housing: option })}
                    />
                    <Input
                      title="Monthly income"
                      value={form.monthly_income}
                      type="integer"
                      onInput={(value) => setForm({ ...form, monthly_income: value })}
                      step={1}
                      min={620}
                      max={298748}
                      error={validationError.monthly_income}
                    />
                    <Input
                      title="Quantity of dependents"
                      value={form.quantity_of_dependents}
                      type="integer"
                      onInput={(value) => setForm({ ...form, quantity_of_dependents: value })}
                      step={1}
                      min={0}
                      max={10}
                      error={validationError.quantity_of_dependents}
                    />
                  </div>
                </div>
                <div className={styles.footerBox}>
                  <div className={styles.buttonGroup}>
                    <Button pending={pending} onClick={handleCalculate}>
                      Calculate
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.result}>
            {!!contextApplication.data?.scoring_log && <ResultBox />}
          </div>
        </>
      ) : (
        <div className={styles.loading_container}>
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Scoring;
