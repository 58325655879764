import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, View, Text, StyleSheet, Font, PDFViewer } from "@react-pdf/renderer";

import global from "global";
import { useSelector } from "store/hooks";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import styles from "./result.module.scss";
import request from "../../request";
import { branches, employment_spheres, locations, type_of_products } from "utils/constants";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px"
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px"
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px"
  },
  text: {
    fontSize: "14px"
  },
  table: {
    backgroundColor: "#fff"
  },
  tr: {
    display: "flex",
    flexDirection: "row"
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px"
  },
  result: {
    fontSize: "18px",
    fontWeight: 700
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

const Result = () => {
  const navigate = useNavigate();
  const [shapeLoading, setShapeLoading] = React.useState(true);
  const contextApplication = useSelector((state) => state.application);
  const [macrodata, setMacrodata] = useState<global.macroData>();
  const [pending, setPending] = useState(false);

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  React.useEffect(() => {
    setPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d))
      .then(() => setPending(false));
  }, []);

  return (
    <div className={styles.result}>
      {!pending ? (
        <div className={styles.fields}>
          <div className={styles.pdf}>
            <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
              <Pdf contextApplication={contextApplication} macrodata={macrodata} />
            </PDFViewer>
          </div>
          {contextApplication.data?.scoring_log?.shap_link && (
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Decision Shapley Values</div>
              {shapeLoading && (
                <div className={styles.loading_shap}>
                  <Loading />
                </div>
              )}
              <iframe
                title="shap_link"
                src={contextApplication.data?.scoring_log?.shap_link}
                style={{ width: "100%" }}
                onLoad={() => setShapeLoading(false)}
              ></iframe>
              <div
                className={styles.gpt_text}
                dangerouslySetInnerHTML={{
                  __html: contextApplication.data?.gpt_text.en
                    .replaceAll("\n", "<br/>")
                    .replaceAll("\t", `<span style="display: inline-block; width: 20px"></span>`)
                }}
              />{" "}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loading_macro_data}>
          <Loading />
        </div>
      )}
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
  macrodata
}: {
  contextApplication: global.contextApplication;
  macrodata?: global.macroData;
}) => {
  return (
    <Document>
      {/* Scoring */}
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.heading}>
          <Text>Scoring report</Text>
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.text}>Application: {contextApplication.data?.id}</Text>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Amount</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.loan_amount} GTQ</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Loan interest rate</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.bank_interest_rate}%</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Duration</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.duration_at_disbursement_months} month(s)</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Education level</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.education_level}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Client residence</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {
                    locations.find((e) => e.text === contextApplication.data?.scoring_log?.location)
                      ?.code
                  }{" "}
                  ({contextApplication.data?.scoring_log.location})
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Family status</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.family_status}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Employment sphere</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {
                    employment_spheres.find(
                      (e) => e.text === contextApplication.data?.scoring_log?.employment_sphere
                    )?.code
                  }{" "}
                  ({contextApplication.data?.scoring_log.employment_sphere})
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Branch</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {
                    branches.find((e) => e.text === contextApplication.data?.scoring_log?.branch)
                      ?.code
                  }{" "}
                  ({contextApplication.data?.scoring_log.branch})
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Type of product</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {
                    type_of_products.find(
                      (e) => e.text === contextApplication.data?.scoring_log?.type_of_product
                    )?.code
                  }{" "}
                  ({contextApplication.data?.scoring_log.type_of_product})
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Age</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.age}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Gender</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.gender}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Presence of housing</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>
                  {contextApplication.data?.scoring_log?.presence_of_housing ? "Yes" : "No"}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Monthly income</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.monthly_income}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Quantity of dependents</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.quantity_of_dependents}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Inflation rate</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.inflation_rate}</Text>
              </View>
            </View>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Remittances</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data?.scoring_log?.remittances}</Text>
              </View>
            </View>
          </View>
          <View style={pdfStyles.bottom}>
            <Text style={pdfStyles.result}>
              Result: {contextApplication.data?.is_approved ? "Approved" : "Rejected"}{" "}
              {contextApplication.data?.scoring_log?.prediction}%
            </Text>
            <Text style={pdfStyles.result}>
              Threshold: {(Number(contextApplication.data?.scoring_log?.threshold) * 100).toFixed()}
              %
            </Text>
          </View>
        </View>
      </Page>
      {/* Macrodata */}
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.heading}>
          <Text>Macro variables</Text>
        </View>
        {macrodata && (
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.text}>Application: {contextApplication.data?.id}</Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Inflation rate</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log.inflation_rate ??
                      macrodata.inflation_rate}{" "}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Interest rate</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log.interest_rate ?? macrodata.interest_rate}{" "}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Remittances</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log.remittances ?? macrodata.remittances}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default React.memo(Result, () => true);
