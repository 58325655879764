const transcript = {
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "admin":
          return "Admin";
        case "root":
          return "ROOT";
        default:
          return "Credit expert";
      }
    }
  }
};

export default transcript;
