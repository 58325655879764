export const education_levels = [
  "Middle School",
  "Elementary School",
  "None",
  "College",
  "High School",
  "Technicals Degree",
  "Others"
];

export const locations = [
  { code: "100101", text: "AMATITLÁN" },
  { code: "100102", text: "CHINAUTLA" },
  { code: "100103", text: "CHUARRANCHO" },
  { code: "100104", text: "FRAIJANES" },
  { code: "100105", text: "GUATEMALA" },
  { code: "100106", text: "MIXCO" },
  { code: "100107", text: "PALENCIA" },
  { code: "100108", text: "SAN JOSÉ DEL GOLFO" },
  { code: "100109", text: "SAN JOSÉ PINULA" },
  { code: "100110", text: "SAN JUAN SACATEPÉQUEZ" },
  { code: "100111", text: "SAN MIGUEL PETAPA" },
  { code: "100112", text: "SAN PEDRO AYAMPUC" },
  { code: "100113", text: "SAN PEDRO SACATEPÉQUEZ" },
  { code: "100114", text: "SAN RAYMUNDO" },
  { code: "100115", text: "SANTA CATARINA PINULA" },
  { code: "100116", text: "VILLA CANALES" },
  { code: "100117", text: "VILLA NUEVA" },
  { code: "110101", text: "CAHABÓN" },
  { code: "110102", text: "CHAHAL" },
  { code: "110103", text: "CHISEC" },
  { code: "110104", text: "COBAN" },
  { code: "110105", text: "FRAY BARTOLOMÉ DE LAS CASAS" },
  { code: "110106", text: "LANQUÍN" },
  { code: "110107", text: "PANZOS" },
  { code: "110108", text: "SAN CRISTÓBAL VERAPAZ" },
  { code: "110109", text: "SAN JUAN CHAMELCO" },
  { code: "110110", text: "SAN PEDRO CARCHÁ" },
  { code: "110111", text: "SANTA CRUZ VERAPAZ" },
  { code: "110112", text: "SENAHÚ" },
  { code: "110113", text: "TACTIC" },
  { code: "110114", text: "TAMAHU" },
  { code: "110115", text: "SAN MIGUEL TUCURÚ" },
  { code: "110116", text: "SANTA CATALINA LA TINTA" },
  { code: "110117", text: "RAXRUHÁ" },
  { code: "110201", text: "CUBULCO" },
  { code: "110202", text: "EL CHOL" },
  { code: "110203", text: "GRANADOS" },
  { code: "110204", text: "PURULHÁ" },
  { code: "110205", text: "RABINAL" },
  { code: "110206", text: "SALAMÁ" },
  { code: "110207", text: "SAN JERÓNIMO" },
  { code: "110208", text: "SAN MIGUEL CHICAJ" },
  { code: "120101", text: "EL ESTOR" },
  { code: "120102", text: "LIVINGSTON" },
  { code: "120103", text: "LOS AMATES" },
  { code: "120104", text: "MORALES" },
  { code: "120105", text: "PUERTO BARRIOS" },
  { code: "120201", text: "CAMOTÁN" },
  { code: "120202", text: "CHIQUIMULA" },
  { code: "120203", text: "CONCEPCIÓN LAS MINAS" },
  { code: "120204", text: "ESQUIPULAS" },
  { code: "120205", text: "IPALA" },
  { code: "120206", text: "JOCOTÁN" },
  { code: "120207", text: "OLOPA" },
  { code: "120208", text: "QUEZALTEPEQUE" },
  { code: "120209", text: "SAN JACINTO" },
  { code: "120210", text: "SAN JOSÉ LA ARADA" },
  { code: "120211", text: "SAN JUAN ERMITA" },
  { code: "120301", text: "CABAÑAS" },
  { code: "120302", text: "ESTANZUELA" },
  { code: "120303", text: "GUALÁN" },
  { code: "120304", text: "HUITE" },
  { code: "120305", text: "LA UNIÓN" },
  { code: "120306", text: "RIO HONDO" },
  { code: "120307", text: "SAN DIEGO" },
  { code: "120308", text: "TECULUTÁN" },
  { code: "120309", text: "USUMATLÁN" },
  { code: "120310", text: "ZACAPA" },
  { code: "120311", text: "SAN JORGE" },
  { code: "120401", text: "EL JÍCARO" },
  { code: "120402", text: "GUASTATOYA" },
  { code: "120403", text: "MORAZÁN" },
  { code: "120404", text: "SAN AGUSTÍN ACASAGUASTLÁN" },
  { code: "120405", text: "SAN ANTONIO LA PAZ" },
  { code: "120406", text: "SAN CRISTÓBAL ACASAGUASTLÁN" },
  { code: "120407", text: "SANARATE" },
  { code: "120408", text: "SANSARE" },
  { code: "130101", text: "AGUA BLANCA" },
  { code: "130102", text: "ASUNCIÓN MITA" },
  { code: "130103", text: "ATESCATEMPA" },
  { code: "130104", text: "COMAPA" },
  { code: "130105", text: "CONGUACO" },
  { code: "130106", text: "EL ADELANTO" },
  { code: "130107", text: "EL PROGRESO" },
  { code: "130108", text: "JALPATAGUA" },
  { code: "130109", text: "JERÉZ" },
  { code: "130110", text: "JUTIAPA" },
  { code: "130111", text: "MOYUTA" },
  { code: "130112", text: "PASACO" },
  { code: "130113", text: "QUEZADA" },
  { code: "130114", text: "SAN JOSÉ ACATEMPA" },
  { code: "130115", text: "SANTA CATARINA MITA" },
  { code: "130116", text: "YUPILTEPEQUE" },
  { code: "130117", text: "ZAPOTITLÁN" },
  { code: "130201", text: "JALAPA" },
  { code: "130202", text: "MATAQUESCUINTLA" },
  { code: "130203", text: "MONJAS" },
  { code: "130204", text: "SAN CARLOS ALZATATE" },
  { code: "130205", text: "SAN LUIS JILOTEPEQUE" },
  { code: "130206", text: "SAN MANUEL CHAPARRÓN" },
  { code: "130207", text: "SAN PEDRO PINULA" },
  { code: "150130", text: "TECÚN UMÁN" },
  { code: "150131", text: "LA BLANCA" },
  { code: "150201", text: "ALMOLONGA" },
  { code: "150202", text: "CABRICÁN" },
  { code: "150203", text: "CAJOLA" },
  { code: "150204", text: "CANTEL" },
  { code: "150205", text: "COATEPEQUE" },
  { code: "150206", text: "COLOMBA COSTA CUCA" },
  { code: "150207", text: "CONCEPCIÓN CHIQUIRICHAPA" },
  { code: "150208", text: "EL PALMAR" },
  { code: "150209", text: "FLORES COSTA CUCA" },
  { code: "150210", text: "GENOVA COSTA CUCA" },
  { code: "150211", text: "HUITÁN" },
  { code: "150212", text: "LA ESPERANZA" },
  { code: "150213", text: "OLINTEPEQUE" },
  { code: "150214", text: "PALESTINA DE LOS ALTOS" },
  { code: "150215", text: "QUETZALTENANGO" },
  { code: "150216", text: "SALCAJÁ" },
  { code: "150217", text: "SAN CARLOS SIJA" },
  { code: "150218", text: "SAN FRANCISCO LA UNIÓN" },
  { code: "150219", text: "SAN JUAN OSTUNCALCO" },
  { code: "150220", text: "SAN MARTÍN SACATEPÉQUEZ" },
  { code: "150221", text: "SAN MATEO" },
  { code: "150222", text: "SAN MIGUEL SIGUILÁ" },
  { code: "150223", text: "SIBILIA" },
  { code: "150224", text: "ZUNIL" },
  { code: "150301", text: "MOMOSTENANGO" },
  { code: "150302", text: "SAN ANDRÉS XECUL" },
  { code: "150303", text: "SAN BARTOLO" },
  { code: "150304", text: "SAN CRISTÓBAL TOTONICAPÁN" },
  { code: "150305", text: "SAN FRANCISCO EL ALTO" },
  { code: "150306", text: "SANTA LUCÍA LA REFORMA" },
  { code: "150307", text: "SANTA MARÍA CHIQUIMULA" },
  { code: "150308", text: "TOTONICAPÁN" },
  { code: "150401", text: "CONCEPCIÓN" },
  { code: "150402", text: "NAHUALÁ" },
  { code: "150403", text: "PANAJACHEL" },
  { code: "150404", text: "SAN ANDRÉS SEMETABAJ" },
  { code: "150405", text: "SAN ANTONIO PALOPÓ" },
  { code: "150406", text: "SAN JOSÉ CHACAYA" },
  { code: "150407", text: "SAN JUAN LA LAGUNA" },
  { code: "150408", text: "SAN LUCAS TOLIMÁN" },
  { code: "150409", text: "SAN MARCOS LA LAGUNA" },
  { code: "150410", text: "SAN PABLO LA LAGUNA" },
  { code: "150411", text: "SAN PEDRO LA LAGUNA" },
  { code: "150412", text: "SANTA CATARINA IXTAHUACÁN" },
  { code: "150413", text: "SANTA CATARINA PALOPÓ" },
  { code: "150414", text: "SANTA CLARA LA LAGUNA" },
  { code: "150415", text: "SANTA CRUZ LA LAGUNA" },
  { code: "150416", text: "SANTA LUCÍA UTATLÁN" },
  { code: "150417", text: "SANTA MARÍA VISITACIÓN" },
  { code: "150418", text: "SANTIAGO ATITLÁN" },
  { code: "150419", text: "SOLOLÁ" },
  { code: "150501", text: "ASINTAL" },
  { code: "150502", text: "CHAMPERICO" },
  { code: "150503", text: "NUEVO SAN CARLOS" },
  { code: "150504", text: "RETALHULEU" },
  { code: "150505", text: "SAN ANDRÉS VILLA SECA" },
  { code: "150506", text: "SANTA CRUZ MULUA" },
  { code: "150507", text: "SAN FELIPE" },
  { code: "150508", text: "SAN MARTÍN ZAPOTITLÁN" },
  { code: "150509", text: "SAN SEBASTIÁN" },
  { code: "150601", text: "CHICACAO" },
  { code: "150602", text: "CUYOTENANGO" },
  { code: "150603", text: "MAZATENANGO" },
  { code: "150604", text: "PATULUL" },
  { code: "150605", text: "PUEBLO NUEVO" },
  { code: "150606", text: "RÍO BRAVO" },
  { code: "150607", text: "SAMAYAC" },
  { code: "150608", text: "SAN ANTONIO SUCHITEPÉQUEZ" },
  { code: "150609", text: "SAN BERNARDINO" },
  { code: "150610", text: "SAN FRANCISCO ZAPOTITLÁN" },
  { code: "150611", text: "SAN GABRIEL" },
  { code: "150612", text: "SAN JOSÉ EL ÍDOLO" },
  { code: "150613", text: "SAN JUAN BAUTISTA" },
  { code: "150614", text: "SAN LORENZO" },
  { code: "150615", text: "SAN MIGUEL PANAN" },
  { code: "150616", text: "SAN PABLO JOCOPILAS" },
  { code: "150617", text: "SANTA BÁRBARA" },
  { code: "150618", text: "SANTO DOMINGO SUCHITEPÉQUEZ" },
  { code: "150619", text: "SANTO TOMÁS LA UNIÓN" },
  { code: "150620", text: "ZUNILITO" },
  { code: "150621", text: "SAN JOSÉ LA MÁQUINA" },
  { code: "160101", text: "AGUACATÁN" },
  { code: "160102", text: "SANTA CRUZ BARILLAS" },
  { code: "160103", text: "CHIANTLA" },
  { code: "160104", text: "COLOTENANGO" },
  { code: "160105", text: "CONCEPCIÓN HUISTA" },
  { code: "160106", text: "CUILCO" },
  { code: "160107", text: "HUEHUETENANGO" },
  { code: "160108", text: "SAN ILDEFONSO IXTAHUACÁN" },
  { code: "160109", text: "JACALTENANGO" },
  { code: "160110", text: "LA DEMOCRACIA" },
  { code: "160111", text: "LA LIBERTAD" },
  { code: "160112", text: "MALACATANCITO" },
  { code: "160113", text: "NENTON" },
  { code: "160114", text: "SAN ANTONIO HUISTA" },
  { code: "160115", text: "SAN GASPAR IXCHIL" },
  { code: "160116", text: "SAN JUAN ATITÁN" },
  { code: "160117", text: "SAN JUAN IXCOY" },
  { code: "160118", text: "SAN MATEO IXTATAN" },
  { code: "160119", text: "SAN MIGUEL ACATÁN" },
  { code: "160120", text: "SAN PEDRO NECTA" },
  { code: "160121", text: "SAN RAFAEL LA INDEPENDENCIA" },
  { code: "160122", text: "SAN RAFAEL PETZAL" },
  { code: "160123", text: "SAN SEBASTIÁN COATAN" },
  { code: "160124", text: "SAN SEBASTIÁN HUEHUETENANGO" },
  { code: "160125", text: "SANTA ANA HUISTA" },
  { code: "160126", text: "SANTA BÁRBARA" },
  { code: "160127", text: "SANTA EULALIA" },
  { code: "160128", text: "SANTIAGO CHIMALTENANGO" },
  { code: "160129", text: "SAN PEDRO SOLOMA" },
  { code: "160130", text: "TECTITLÁN" },
  { code: "160131", text: "TODOS SANTOS CUCHUMATANES" },
  { code: "160132", text: "UNIÓN CANTINIL" },
  { code: "160133", text: "PETATÁN" },
  { code: "160201", text: "CANILLA" },
  { code: "160202", text: "CHAJUL" },
  { code: "160203", text: "CHICAMÁN" },
  { code: "160204", text: "CHICHÉ" },
  { code: "160205", text: "CHICHICASTENANGO" },
  { code: "160206", text: "CHINIQUÉ" },
  { code: "160207", text: "CUNÉN" },
  { code: "160208", text: "IXCÁN" },
  { code: "160209", text: "JOYABAJ" },
  { code: "160210", text: "NEBAJ" },
  { code: "160211", text: "PACHALÚN" },
  { code: "160212", text: "PATZITE" },
  { code: "160213", text: "SACAPULAS" },
  { code: "160214", text: "SAN ANDRÉS SALCABAJA" },
  { code: "160215", text: "SAN ANTONIO ILOTENANGO" },
  { code: "160216", text: "SAN BARTOLOMÉ JOCOTENANGO" },
  { code: "160217", text: "SAN JUAN COTZAL" },
  { code: "160218", text: "SAN MIGUEL USPANTÁN" },
  { code: "160219", text: "SAN PEDRO JOCOPILAS" },
  { code: "160220", text: "SANTA CRUZ DEL QUICHÉ" },
  { code: "160221", text: "ZACUALPA" },
  { code: "170101", text: "DOLORES" },
  { code: "170102", text: "FLORES" },
  { code: "170103", text: "LA LIBERTAD" },
  { code: "170104", text: "MELCHOR DE MENCOS" },
  { code: "170105", text: "POPTÚN" },
  { code: "170106", text: "SAN ANDRÉS" },
  { code: "170107", text: "SAN BENITO" },
  { code: "170108", text: "SAN FRANCISCO" },
  { code: "170109", text: "SAN JOSÉ" },
  { code: "170110", text: "SAN LUIS" },
  { code: "170111", text: "SANTA ANA" },
  { code: "170112", text: "SAYAXCHE" },
  { code: "170113", text: "SANTA ELENA (CIUDAD DEL MUNICIPIO DE FLORES)" },
  { code: "170114", text: "LAS CRUCES" },
  { code: "170115", text: "EL CHAL" }
];

export const family_statuses = ["Single", "Married", "Commonlaw Married"];

export const employment_spheres = [
  { code: "A", text: "Agriculture, forestry and fishing" },
  { code: "A1", text: "Growing of non-perennial crops, perennial crops, and plant propagation" },
  { code: "A2", text: "Animal production" },
  { code: "A3", text: "Mixed farming" },
  { code: "A4", text: "Support activities to agriculture and post-harvest crop activities" },
  { code: "A5", text: "Hunting, trapping and related service activities" },
  { code: "A6", text: "Forestry and logging" },
  { code: "A7", text: "Fishing and aquaculture" },
  { code: "B", text: "Mining and quarrying" },
  { code: "C", text: "Manufacturing" },
  { code: "C1", text: "Manufacture of food products, beverages, and tobacco products" },
  {
    code: "C10",
    text: "Manufacture of machinery and equipment n.e.c., and repair and installation of machinery and equipment"
  },
  {
    code: "C11",
    text: "Manufacture of motor vehicles, trailers and semi-trailers, and manufacture of other transport equipment"
  },
  { code: "C12", text: "Manufacture of furniture" },
  { code: "C13", text: "Other manufacturing" },
  {
    code: "C2",
    text: "Manufacture of textiles, wearing apparel, and leather and related products"
  },
  {
    code: "C3",
    text: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials"
  },
  {
    code: "C4",
    text: "Manufacture of paper and paper products, and printing and reproduction of recording media"
  },
  {
    code: "C5",
    text: "Manufacture of coke and refined petroleum products, and chemicals and chemical products"
  },
  {
    code: "C6",
    text: "Manufacture of basic pharmaceutical products and pharmaceutical preparations"
  },
  {
    code: "C7",
    text: "Manufacture of rubber and plastics products, and other non-metallic mineral products"
  },
  {
    code: "C8",
    text: "Manufacture of basic metals, and fabricated metal products, except machinery and equipment"
  },
  {
    code: "C9",
    text: "Manufacture of computer, electronic and optical products, and electrical equipment"
  },
  { code: "D", text: "Electricity, gas, steam and air conditioning supply" },
  { code: "E", text: "Water supply; sewerage, waste management and remediation activities" },
  { code: "F", text: "Construction" },
  { code: "G", text: "Wholesale and retail trade; repair of motor vehicles and motorcycles" },
  { code: "G1", text: "Wholesale and retail trade and repair of motor vehicles and motorcycles" },
  { code: "G10", text: "Retail sale via stalls and markets" },
  { code: "G11", text: "Retail trade not in stores, stalls or markets" },
  { code: "G2", text: "Wholesale trade, except of motor vehicles and motorcycles" },
  { code: "G3", text: "Retail sale in non-specialized stores" },
  { code: "G4", text: "Retail sale of food, beverages and tobacco in specialized stores" },
  { code: "G5", text: "Retail sale of automotive fuel in specialized stores" },
  {
    code: "G6",
    text: "Retail sale of information and communications equipment in specialized stores"
  },
  { code: "G7", text: "Retail sale of other household equipment in specialized stores" },
  { code: "G8", text: "Retail sale of cultural and recreation goods in specialized stores" },
  { code: "G9", text: "Retail sale of other goods in specialized stores" },
  { code: "H", text: "Transportation and storage" },
  { code: "I", text: "Accommodation and food service activities" },
  { code: "I1", text: "Accommodation" },
  { code: "I2", text: "Food and beverage service activities" },
  { code: "J", text: "Information and communication" },
  { code: "K", text: "Financial and insurance activities" },
  { code: "L", text: "Real estate activities" },
  { code: "M", text: "Professional, scientific and technical activities" },
  { code: "N", text: "Administrative and support service activities" },
  { code: "O", text: "Public administration and defence; compulsory social security" },
  { code: "P", text: "Education" },
  { code: "Q", text: "Human health and social work activities" },
  { code: "R", text: "Arts, entertainment and recreation" },
  { code: "S", text: "Other service activities" },
  {
    code: "T",
    text: "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use"
  },
  { code: "U", text: "Activities of extraterritorial organizations and bodies" }
];

export const branches = [
  { code: "1", text: "CAPITAL SAN JUAN" },
  { code: "2", text: "VILLA NUEVA" },
  { code: "3", text: "JUTIAPA" },
  { code: "5", text: "JALAPA" },
  { code: "6", text: "ESCUINTLA" },
  { code: "9", text: "SANTA LUCIA" },
  { code: "12", text: "BOCA DEL MONTE" },
  { code: "13", text: "MAZATENANGO" },
  { code: "14", text: "BARBERENA" },
  { code: "15", text: "PUERTO SAN JOSE" },
  { code: "16", text: "QUETZALTENANGO" },
  { code: "17", text: "HUEHUETENANGO" },
  { code: "18", text: "COATEPEQUE" },
  { code: "19", text: "QUICHE" },
  { code: "20", text: "COBAN" },
  { code: "21", text: "JACALTENANGO" },
  { code: "22", text: "CAMOJA" },
  { code: "23", text: "SALAMA" },
  { code: "24", text: "SOLOLA" },
  { code: "25", text: "SAN MARCOS" },
  { code: "27", text: "TOTONICAPAN" },
  { code: "28", text: "AMATITLAN" },
  { code: "29", text: "CHIMALTENANGO" },
  { code: "30", text: "CHIQUIMULILLA" },
  { code: "34", text: "USPANTAN" },
  { code: "41", text: "SOLOMA" }
];

export const type_of_products = [
  { code: "BCCREDFA28", text: "CREDITO BANCA GRUPAL CREDIFACIL 28 DIAS" },
  { code: "BCCREDFA35", text: "CREDITO BANCA GRUPAL CREDIFACIL 35 DIAS" },
  { code: "BCCREDFACL", text: "CREDITO BANCA GRUPAL CREDIFACIL MENSUAL" },
  { code: "BCGRUPAL", text: "CREDITO BANCA GRUPAL MENSUAL" },
  { code: "BCGRUPAL28", text: "CREDITO BANCA GRUPAL 28 DIAS" },
  { code: "BCGRUPAL35", text: "CREDITO BANCA GRUPAL 35 DIAS" },
  { code: "BCINCORP", text: "CREDITO BANCA GRUPAL INCORPORACION MENSUAL" },
  { code: "BCINCORP28", text: "CREDITO BANCA GRUPAL INCORPORACION 28 DIAS" },
  { code: "BCINCORP35", text: "CREDITO BANCA GRUPAL INCORPORACION 35 DIAS" },
  { code: "BCPARALE28", text: "CREDITO BANCA GRUPAL PARALELO 28 DIAS" },
  { code: "BCPARALE35", text: "CREDITO BANCA GRUPAL PARALELO 35 DIAS" },
  { code: "BCPARALELO", text: "CREDITO BANCA GRUPAL PARALELO MENSUAL" },
  { code: "CIAGRO", text: "CREDITO INDIVIDUAL AGRO" },
  { code: "CIALIANZA", text: "CREDITO INDIVIDUAL ALIANZA <= 160,000" },
  { code: "CIASALARDO", text: "CREDITO INDIVIDUAL ASALARIADO" },
  { code: "CICLASICO", text: "CREDITO INDIVIDUAL CLASICO" },
  { code: "CIESPECIA2", text: "CREDITO INDIVIDUAL CONDICIONES ESPECIALES > 160,000" },
  { code: "CIESPECIAL", text: "CREDITO INDIVIDUAL CONDICIONES ESPECIALES <= 160,000" },
  { code: "CIREGRESAF", text: "CREDITO INDIVIDUAL REGRESA FINCA" },
  { code: "CITECONF", text: "CREDITO INDIVIDUAL TE CONFIAMOS MENSUAL" },
  { code: "CITECONF15", text: "CREDITO INDIVIDUAL TE CONFIAMOS QUINCENAL" },
  { code: "CITECONFSS", text: "CREDITO INDIVIDUAL TE CONFIAMOS SEMANAL" },
  { code: "CITEMPORAD", text: "CREDITO INDIVIDUAL DE TEMPORADA" },
  { code: "CREDIFACIL", text: "CREDITO INDIVIDUAL CREDIFACIL" },
  { code: "LDC", text: "Líneas de Crédito" },
  { code: "PEM", text: "CREDITO INDIVIDUAL PEQUEÑA EMPRESA" }
];
