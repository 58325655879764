import React from "react";
import styles from "./create-modal.module.scss";

const BulkScoringModal = ({ error, onClose }: { error: string; onClose: () => void }) => {
  const [text] = React.useState(error);

  return (
    <div className={styles.modal2} onMouseDown={onClose}>
      <div className={styles.modalWrap} onMouseDown={(event) => event.stopPropagation()}>
        {text}
      </div>
    </div>
  );
};

export default BulkScoringModal;
