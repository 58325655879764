import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "components/input";
import Button from "components/button";
import request from "request";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [sum, setSum] = React.useState("");
  const [bankInterestRate, setBankInterestRate] = React.useState("");
  const [duration, setDuration] = useState("");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "sum" | "bankInterestRate" | "duration";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (sum.trim().length === 0) {
      return setValidError({
        name: "sum",
        value: "Amount is required"
      });
    } else if (bankInterestRate.trim().length === 0) {
      return setValidError({
        name: "bankInterestRate",
        value: "Loan interest rate is required"
      });
    } else if (bankInterestRate.trim().length === 0) {
      return setValidError({
        name: "duration",
        value: "Duration is required"
      });
    } else setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        loan_amount: sum,
        bank_interest_rate: bankInterestRate,
        duration_at_disbursement_months: duration
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/scoring`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>New application</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Amount"
              type="float"
              value={sum}
              onInput={(value) => setSum(value)}
              step={500}
              min={500}
              max={311234.71}
              unit="GTQ"
              placeholder={`min: 500  max: 311234.71`}
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Loan interest rate"
              type="integer"
              value={bankInterestRate}
              onInput={(value) => setBankInterestRate(value)}
              step={1}
              min={6}
              max={75}
              unit="%"
              placeholder={`min: 6  max: 75`}
            />
            {validError?.name === "bankInterestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Duration"
              type="integer"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={3}
              max={73}
              unit="month(s)"
              placeholder={`min: 3  max: 73`}
            />
            {validError?.name === "bankInterestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
