import React from "react";
import { AxiosError } from "axios";
import { useAlert } from "react-alert";

import Button from "components/button";
import RangeBox from "components/range-box";
import Input from "components/input";
import request from "request";
import styles from "./settings.module.scss";

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);

  const [scoringThreshold, setScoringThreshold] = React.useState(0);
  const [CPI, setCPI] = React.useState("");
  const [inflationRate, setInflationRate] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [remittances, setRemittances] = React.useState("");

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: scoringThreshold / 100,
        CPI: Number(CPI),
        inflation_rate: Number(inflationRate),
        interest_rate: Number(interestRate),
        remittances: Number(remittances)
      })
      .then(() => alert.success("Saved!"))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setScoringThreshold(Math.round(data.threshold * 100));
        setCPI(data.cpi);
        setInflationRate(data.inflation_rate);
        setInterestRate(data.interest_rate);
        setRemittances(data.remittances);
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Macro variables</div>
          <div className={styles.fieldList}>
            <Input
              title="Inflation rate"
              value={inflationRate}
              type="float"
              onInput={(value) => setInflationRate(value)}
              step={0.1}
            />
            <Input
              title="Interest rate"
              value={interestRate}
              type="float"
              onInput={(value) => setInterestRate(value)}
              step={0.1}
            />
            <Input
              title="Remittances"
              value={remittances}
              type="float"
              onInput={(value) => setRemittances(value)}
              step={0.1}
            />
          </div>
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Scoring Threshold (%)</div>
          <RangeBox value={scoringThreshold} setValue={setScoringThreshold} />
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
