import isDev from "./is-dev";

import isTestDeploy from "./is-test-deploy";

const devURL = "https://finca-gt.southcentralus.cloudapp.azure.com/dev";

const buildURL = "https://finca-gt.southcentralus.cloudapp.azure.com/prod";

const baseURL = isDev || isTestDeploy ? devURL : buildURL;

export default baseURL;
